export default {
  nav: {
    docs: {
      en: 'About',
    },
    terms: {
      en: 'Terms & Conditions',
    },
    policy: {
      en: 'Privacy Policy',
    },
    faq: {
      en: 'FAQ',
    },
  },
}
