import cx from 'classnames'
import { Href } from 'components/navigation'


type LogoProps = {
  className?: string
}

const Logo: React.FC<LogoProps> = (props) => {
  const { className } = props

  return (
    <Href to="/" className={cx('flex items-center', className)}>
      <svg
        className="h-full"
        width="120" // Further reduced width
        height="35" // Further reduced height
        xmlns="http://www.w3.org/2000/svg"
      >
        <text
          x="0"
          y="15" // Adjusted y position for centering
          fontSize="18" // Reduced font size to half
          fontWeight="bold" // Made the text bold
          className="logo-text" // Added a class for custom font
          fill="currentColor"
        >
          BetXChain
        </text>
      </svg>
    </Href>
  )
}

export default Logo
